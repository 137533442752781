
  
  .diamond-btn:hover {
    background-color: #324151;
  }
  
  .diamond-btn-content {
    padding-top: 75px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
 
  
  /* Position the button to the left on large screens */
  .button-container {
    display: flex;
    justify-content: flex-start; 
  }
  
  /* Center the button on smaller screens */
  @media (max-width: 767px) {
    .button-container {
      justify-content: center; /* Centers the button on mobile devices */
      margin-top: 20px; /* Adds spacing above the button on smaller screens */
    }
  }
  